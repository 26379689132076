import React, { useState, useEffect } from "react";

import {
  TextField,
  Grid,
  Link as MuiLink,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@mui/material'
import { useOktaAuth } from '@okta/okta-react';
import {  useLocation } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import loginImage from '../../../assets/images/testImages/Login.png'
import veriskLogo from '../../../assets/images/icons/verisk.png'
import { useMaterialUIController,setLayout } from "../../../context";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import { useMessage } from "../../../context/MessageProvider";



function Login() {
  const [rememberMe, setRememberMe] = useState(false);
  const { oktaAuth } = useOktaAuth();
  const { showErrorMessage } = useMessage();
  
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  const schema = yup.object().shape({
    email: yup.string().required("Please provide email"),

  });

  const {
    register,
    handleSubmit,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
   
    oktaAuth.signInWithRedirect({ originalUri: '/claims' });

  };

  const handleRegisterClick = ()=>
  {
    window.open("https://sso.g1.xactware.com/settings/create?returnUrl=https%3A%2F%2Fsso.g1.xactware.com", '_blank', 'noopener,noreferrer');
  }

 
  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);
 
  const onError = (errors) => 
    { if (errors?.email?.message)
      showErrorMessage("Please provide email")
      else
      showErrorMessage("Something went wrong")
    
    }
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
  <>
    
    <Grid   container sx={{ height: '100vh', width :'100vw' , overflow: 'hidden', margin: 0, padding: 0, position :"absolute",alignItems: 'center' }}>
  {/* Left side - Image */}
      <Grid item xs={6} md={6} lg={6} sx={{ height: '100vh',  width :'100vw', overflow: 'hidden', margin: 0, padding: 0,}}>
        <img 
  src={loginImage} 
  alt="Login" 
  loading="lazy"
  style={{
    width: '100%',
    height: '100vh',
    objectFit: 'cover',
    objectPosition: 'center',
  }} />

              <MDTypography
                color = "veriskWhite"
                fontWeight = "light"
                sx={{ 
                    position : "absolute",
                    top : 20,
                    left : 10,
                    fontSize : pxToRem(30),
                    padding : pxToRem(5)
                  }}
              >
                Property Hub
              </MDTypography>
      </Grid>


      {/* Right side - Login form */}
      <Grid item xs={6} md={6} lg={6} sx={{ display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems: 'center' }}>
        <MDBox sx={{ width: '100%',mr:25 , ml:25, maxWidth:pxToRem(400)}}>
          <MDBox
            sx={{
             display: 'flex',
             flexDirection : 'column',
              alignItems :'center',
              color: 'white',
              textAlign: 'center',
           
            }}
          >
            <img
              src={veriskLogo}
              alt="Logo"
              loading="lazy"  
              style={{
                width: '12vw',    
                height: '8vh',    
                objectFit: 'contain',
                objectPosition: 'center', 
                marginBottom: '2vh', 
              }}
            />
            <MDTypography fontSize={pxToRem(17)} fontWeight="regular" color='veriskBlack' >
              Welcome! Please login to your account.
            </MDTypography>
          </MDBox>
          <MDBox component="form" onSubmit={handleSubmit(onSubmit, onError)}>
            <TextField
              type="email"
              label="Email"
              variant="standard"
              fullWidth
              margin="normal"
              {...register("email")}
            />
            
            
            <Grid container spacing={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Grid item xs={6} lg={6}>
                <MDButton
                  variant="gradient"
                  color="veriskButtonColor"
                  fullWidth
                  type="submit"
                  sx={{ mt: 3, mb: 2,py: pxToRem(2),px:pxToRem(10), fontSize:pxToRem(17),fontWeight: 300, textTransform: 'none'  }}
                >
                  Login
                </MDButton>
              </Grid>
              <Grid item xs={6} lg={6}> 
              <MDButton
                variant="gradient"
                color = "transparent"
                fullWidth
                onClick = {()=>handleRegisterClick()}
                sx={{ mt: 3, mb: 2,py: pxToRem(2),px:pxToRem(10),fontSize:pxToRem(17),textTransform: 'none',border: '1px solid #2a7de1'}}
              >
                <MDTypography color='veriskBlack' fontWeight='light' fontSize={pxToRem(17)}>
                  Register
                </MDTypography>
              </MDButton>
              </Grid>
            </Grid> 
            <Grid container spacing={2}>
              
              <Grid item xs={12} sm={12}>
                <MDBox   sx={{display : "flex" , justifyContent:'space-between', alignItems:'center'}}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox  checked={rememberMe} onChange={handleSetRememberMe}  sx={{
            transform: 'scale(1.2)', // Increase checkbox size for better visibility
          }}/>} 
                      label={
                            <MDTypography
                                fontWeight = "regular"
                                color = "veriskBlack"
                                sx={{
                                  fontSize: pxToRem(17),
                                }}
                            >
                              Remember email
                            </MDTypography>} 
                      />
                  </FormGroup>
                  <MuiLink href="https://sso.g1.xactware.com/settings/reset" sx={{ display: 'block', textAlign: 'right' }} target="_blank" rel="noopener noreferrer">
                    <MDTypography
                      fontWeight = "light"
                      color = "veriskBlack"
                      sx={{
                        fontSize: pxToRem(17),
                      }}
                    >
                      Forgot Password?
                    </MDTypography>
                  </MuiLink>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            position: 'fixed',
            bottom: 16,  
            padding: 1,
            alignItems:'center' 
          }}
        >
          <MDTypography variant="body2" color="text.secondary" align="center">
              {"By signing in, you accept Xactware's "}
            <MuiLink href="https://www.verisk.com/privacy-policies/xactware-eula-en-gb/" color="#2a7de1" target="_blank" rel="noopener noreferrer">
              License Agreement 
            </MuiLink>
            {" and "}
            <MuiLink href="https://www.verisk.com/privacy-policies/xactware-privacy-app-en-gb/" color="#2a7de1" target="_blank" rel="noopener noreferrer">
              Privacy Notice 
            </MuiLink>
          </MDTypography>
        </MDBox>
      </Grid>
    </Grid>
      
      </>
  );
}

export default Login;
