import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation,useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Box from '@mui/material/Box';  
import Sidenav from "./components/Sidenav";
import theme from "./assets/theme";
import {routes,claimsRoutes , getRoutes } from "./routes";
import Login from "./layouts/authentication/login";
import { Security, LoginCallback } from '@okta/okta-react';
import { useMaterialUIController, setMiniSidenav } from "./context";
import config from "./core/models/constants/config"
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';


import { useSelector } from "react-redux";

import { getIsClaimSelected } from "./core/reducers/claims-reducer";
import AuthRequiredModal from "./AuthRequiredModal";
import InitAxios from "./core/services/init-axios";
import UnmatchedRouteHandler from "./UnmatchedRouteHandler";

import { useMessage } from "./context/MessageProvider";



const oktaAuth = new OktaAuth(config.oidc);
export default function App() {
  
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false);
  const routesList = getRoutes(routes);
 
  
  const claimRoutesList = getRoutes(claimsRoutes); 
 
  const {
    miniSidenav,
    direction, 
    layout, 
    sidenavColor,
    displayGallery
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const isClaimSelected = useSelector(getIsClaimSelected);
  const {  showErrorMessage } = useMessage();

 
  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect({ originalUri: '/claims' });
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
    
      oktaAuth.token.getWithRedirect({
        responseType: ['id_token', 'token'], 
      })
      await triggerLogin();
    } else {
      setAuthRequiredModalOpen(true);
  
    }
  };

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };


  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };




  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);


  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    
  }, [pathname]);

  return  (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
     <InitAxios showErrorMessage={showErrorMessage}></InitAxios>
     <AuthRequiredModal {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }} />
    <ThemeProvider theme={theme}> 
     <Box display="flex" >
     
      {layout === "dashboard" && displayGallery==false &&(
        
          
          <Sidenav
            color={sidenavColor}
            brandName="Property Hub"
            routes={isClaimSelected?claimsRoutes:routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        
        
      )}
       <Box flexGrow={1} sx={{ marginLeft: 0}}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login/callback" element={<LoginCallback />} />
        {routesList}
        {claimRoutesList}
        <Route path="*" element={<UnmatchedRouteHandler />} />
      </Routes>
      </Box>
      </Box>
     
    </ThemeProvider>
    </Security>
  );
}
