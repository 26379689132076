import React, { createContext, useContext, useState, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';
import pxToRem from '../assets/theme/functions/pxToRem'; 
import PropTypes from 'prop-types';

const MessageContext = createContext();

// Provider component
export const MessageProvider = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState({ open: false, message: '' });
  const [errorMessage, setErrorMessage] = useState({ open: false, message: '' });

  const showSuccessMessage = useCallback((message) => {
    setSuccessMessage({ open: true, message });
  }, []);

  const showErrorMessage = useCallback((message) => {
    setErrorMessage({ open: true, message });
  }, []);

  const closeSuccessMessage = useCallback(() => {
    setSuccessMessage({ open: false, message: '' });
  }, []);

  const closeErrorMessage = useCallback(() => {
    setErrorMessage({ open: false, message: '' });
  }, []);

  return (
    <MessageContext.Provider value={{ showSuccessMessage, showErrorMessage }}>
      {children}
      <SuccessMessageComponent 
        open={successMessage.open} 
        message={successMessage.message} 
        onClose={closeSuccessMessage} 
      />
      <ErrorMessageComponent 
        open={errorMessage.open} 
        message={errorMessage.message} 
        onClose={closeErrorMessage} 
      />
    </MessageContext.Provider>
  );
};

// Custom hook to use the message context
export const useMessage = () => useContext(MessageContext);

// Success Message Component
const SuccessMessageComponent = ({ open, message, onClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={4000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <Alert 
      onClose={onClose}  
      icon={false} 
      closeText=''  
      variant="filled" 
      sx={{ 
        width: '100%', 
        backgroundColor: '#333333', 
        color: '#ffffff', 
        fontWeight: "regular", 
        fontSize: pxToRem(15)
      }}
    >
      {message}
    </Alert>
  </Snackbar>
);

// Error Message Component
const ErrorMessageComponent = ({ open, message, onClose }) => (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={onClose}
        severity={"error"}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
);

SuccessMessageComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  
  ErrorMessageComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };
  

  MessageContext.propTypes = {
    showSuccessMessage: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.func.isRequired,
  };

  MessageProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  