import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

export const SecureRoute = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const location = useLocation();

  if (!authState) {
    return <div>Loading...</div>;
  }

  if (!authState.isAuthenticated) {
    const currentPath = location.pathname;
    oktaAuth.setOriginalUri(currentPath);
    return <Navigate to="/login" />;
  }

  return children;
};

SecureRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

