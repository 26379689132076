import { FAILED, IDLE, LOADING, SUCCEEDED } from "../models/constants/index";
import { createSlice } from "@reduxjs/toolkit";
import { authUserLogin } from "../actions/auth-actions";
var initialState = {
    loadingAuth: IDLE,
    user: undefined,
    token: '',
    isAuthenticated: false
};
export var authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        handleAuth: function (state, payload) { },
    },
    extraReducers: function (builder) {
        builder.addCase(authUserLogin.pending, function (state) {
            state.loadingAuth = LOADING;
        });
        builder.addCase(authUserLogin.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.user = payload;
            state.loadingAuth = SUCCEEDED;
            state.isAuthenticated = true;
        });
        builder.addCase(authUserLogin.rejected, function (state) {
            state.loadingAuth = FAILED;
            state.isAuthenticated = false;
        });
    },
});
export default authSlice.reducer;
export var getUser = function (state) { return state.authState.user; };
export var getAuthStatus = function (state) { return state.authState.isAuthenticated; };
