

import { createContext, useContext, useReducer, useMemo } from "react";


import PropTypes from "prop-types";



const MaterialUI = createContext();


MaterialUI.displayName = "MaterialUIContext";

const DISABLE_ACTION_DRAWER_BUTTON = "DISABLE_ACTION_DRAWER_BUTTON";
const SET_PolicyHolder_Details = "SET_POLICYHOLDER_DETAILS"
const SET_External_User_Task_Assignment_Details = "SET_External_User_Task_Assignment_Details"
const PAGE_LOADING= "PAGE_LOADING"
const SET_TASK_DETAILS = "SET_TASK_DETAILS"
const SET_STATUS_DETAILS = "SET_STATUS_DETAILS"
const REMOVE_STATUS_DETAILS = "REMOVE_STATUS_DETAILS"

function reducer(state, action) {
  switch (action.type) {
    case "SET_ACTIONABLE_SINGLE_ITEM": {
      return { ...state, actionableSingleItem: action.value };
    }
    case "SET_ACTIONABLE_ITEMS": {
      return { ...state, actionableItems: action.value };
    }
    case "ADD_ACTIONABLE_ITEM": {
      return { ...state, actionableItems: [...state.actionableItems, action.value] };
    }
    case "ADD_ACTIONABLE_ITEM_USERNAME": {
      return { ...state, actionableItemsUsernames: [...state.actionableItemsUsernames, action.value] };
    }
    case "SET_ACTIONABLE_ITEM_USERNAMES": {
      return { ...state, actionableItemsUsernames: action.value};
    }
    case "REMOVE_ACTIONABLE_ITEM": {
      return { 
        ...state, 
        actionableItems: state.actionableItems.filter(item => item.mediaIndex !== action.value) 
      };
    }
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case DISABLE_ACTION_DRAWER_BUTTON: {
      return { ...state, isActionDrawerButtonDisabled: action.value };
    }
    case PAGE_LOADING: {
      return { ...state, pageLoading: action.value };
    }
    case SET_PolicyHolder_Details: {
      return { ...state, policyHolderInviteDetails: action?.value };
    }
    case SET_External_User_Task_Assignment_Details: {
      return { ...state, customTaskExternalUserDetails: action?.value };
    }
    case SET_TASK_DETAILS: {
      return { ...state, taskDetails: action?.value };
    }
    case SET_STATUS_DETAILS: {
      
      const newStatusDetail = action?.value;
    
      const existingIndex = state.statusDetails.findIndex(
        (detail) =>
          detail?.statusType.toLowerCase() === newStatusDetail?.statusType?.toLowerCase() &&
          detail?.assignmentID === newStatusDetail.assignmentID
      );

      let updatedStatusDetails;

      if (existingIndex === -1) {

        updatedStatusDetails = [...state.statusDetails, newStatusDetail];
  
        return {
          ...state,
          statusDetails: updatedStatusDetails,
        };
       
      } 
          return state
    }
    case REMOVE_STATUS_DETAILS: {
      const { statusType,assignmentID } = action.value;

      const newDetails = state?.statusDetails?.filter(detail => 
        !(detail?.assignmentID === assignmentID && detail?.statusType?.toLowerCase() === statusType.toLowerCase())
      );
   
      return {
        ...state,
        statusDetails: newDetails,
      };
    }
    case "SET_REFERRAL_DETAILS": {
      return { ...state, referralDetails: action?.value };
    }
    case "SET_ASSIGN_DETAILS": {
      return { ...state, assignDetails: action?.value };
    }
    case "RESET_STATUS_DETAILS": {
      return {
        ...state,
        statusDetails: [],
      };
    }
    case "UPDATE_STATUS_DETAIL_FIELD": {
      const { statusType, fieldName, fieldValue,assignmentID } = action.value;
      const index = state.statusDetails.findLastIndex (detail => (
        detail?.statusType.toLowerCase() === statusType?.toLowerCase() 
        && detail?.assignmentID === assignmentID
      ));
    
      if (index !== -1) {
        const updatedStatusDetails = [...state.statusDetails];
        updatedStatusDetails[index] = {
          ...updatedStatusDetails[index],
          [fieldName]: fieldValue,
        };
       
        return {
          ...state,
          statusDetails: updatedStatusDetails,
        };
      }
      return state;
    }
    case "SET_DISPLAY_GALLERY": {
      return { ...state, displayGallery: action?.value };
    }
    case "SET_MEDIA_INDEX": {
      return { ...state, mediaIndex: action?.value };
    }
    case "SET_TEMP_CLAIM_FLAGS": {
      return { ...state, tempclaimFlags: action?.value };
    }
    case "SET_FINAL_CLAIM_FLAGS": {
      return { ...state,     finalClaimFlags: Array.from(new Set([...state.finalClaimFlags, ...action.value]))};
    }
    case "REMOVE_FINAL_CLAIM_FLAG": {
      return {
        ...state,
        finalClaimFlags: state.finalClaimFlags.filter(flag => flag !== action?.value),
      };
    }
    case "SET_CREATE_CLAIM_BODY": {
     
        return {
          ...state,
          claimRequestBody:{...state.claimRequestBody,
            [action.fieldName]: action.fieldValue,
        },
      }
    }
    case "RESET_CREATE_CLAIM_BODY": {
      return {
        ...state,
        claimRequestBody:action.value
    }
  }
    case "SET_CREATE_ASSIGMENT_BODY": {
      
      return {
        ...state,
        assignmentRequestBody:{...state.assignmentRequestBody,
          [action.fieldName]: action.fieldValue,
      },
    }
  } 
    case "RESET_CREATE_ASSIGMENT_BODY": {
      return {
        ...state,
        assignmentRequestBody:action.value
    }
  }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
    isActionDrawerButtonDisabled: true,
    pageLoading: false,
    policyHolderInviteDetails : {email : "", phone:"", countryCode : ""},
    customTaskExternalUserDetails :  {email : "", phone:"", countryCode : "", firstName :"", lastName: ""},
    taskDetails :{},
    statusDetails : [],
    displayGallery : false,
    mediaIndex : 0,
    actionableItems: [],
    tempclaimFlags : [],
    finalClaimFlags : [],
    actionableSingleItem : [],
    referralDetails : {},
    actionableItemsUsernames : [],
    assignDetails : {},
    claimRequestBody :{},
    assignmentRequestBody : {}

  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setActionableItems = (dispatch, value) => dispatch({ type: "SET_ACTIONABLE_ITEMS", value });
const setActionableItemsUsernames = (dispatch, value) => dispatch({ type: "SET_ACTIONABLE_ITEM_USERNAMES", value });
const setActionableSingleItem = (dispatch, value) => dispatch({ type: "SET_ACTIONABLE_SINGLE_ITEM", value });
const addActionableItem = (dispatch, value) => dispatch({ type: "ADD_ACTIONABLE_ITEM", value });
const addActionableItemUsername = (dispatch, value) => dispatch({ type: "ADD_ACTIONABLE_ITEM_USERNAME", value });
const removeActionableItem = (dispatch, value) => dispatch({ type: "REMOVE_ACTIONABLE_ITEM", value });
const setDisableActionDrawerButton = (dispatch, value) => dispatch({ type: DISABLE_ACTION_DRAWER_BUTTON, value });
const setCreateClaimRequestBody = (dispatch, fieldName,fieldValue) => dispatch({ type: "SET_CREATE_CLAIM_BODY", fieldName,fieldValue });
const resetCreateClaimRequestBody = (dispatch, value) => dispatch({ type: "RESET_CREATE_CLAIM_BODY", value});
const setCreateAssignmentRequestBody = (dispatch, fieldName,fieldValue) => dispatch({ type: "SET_CREATE_ASSIGMENT_BODY", fieldName,fieldValue });
const resetCreateAssignmentRequestBody = (dispatch, value) => dispatch({ type: "RESET_CREATE_ASSIGMENT_BODY", value});
const setPageLoading = (dispatch, value) => dispatch({ type: PAGE_LOADING, value });
const setPolicyHolderInviteDetails = (dispatch, value) => dispatch({ type: SET_PolicyHolder_Details, value });
const setTaskDetails = (dispatch, value) => dispatch({ type: SET_TASK_DETAILS, value });
const setCustomTaskExternalUserDetails = (dispatch, value) => dispatch({ type: SET_External_User_Task_Assignment_Details, value });
const setStatusDetails = (dispatch, value) => dispatch({ type: SET_STATUS_DETAILS, value });
const setAssignDetails = (dispatch, value) => dispatch({ type: "SET_ASSIGN_DETAILS", value });
const resetStatusDetails = (dispatch, value) => dispatch({ type: "RESET_STATUS_DETAILS", value });
const setReferralDetails = (dispatch, value) => dispatch({ type: "SET_REFERRAL_DETAILS", value });
const removeStatusDetails = (dispatch, value) => dispatch({ type: REMOVE_STATUS_DETAILS, value });
const updateStatusDetails = (dispatch, value) => dispatch({ type: "UPDATE_STATUS_DETAIL_FIELD", value });
const setDisplayGallery = (dispatch, value) => dispatch({type: "SET_DISPLAY_GALLERY",value})
const setMediaIndex = (dispatch, value) => dispatch({type: "SET_MEDIA_INDEX",value})
const setTempClaimFlags = (dispatch, value) => dispatch({type: "SET_TEMP_CLAIM_FLAGS", value})
const setFinalClaimFlags = (dispatch, value) => dispatch({type: "SET_FINAL_CLAIM_FLAGS", value})
const removeFinalClaimFlags = (dispatch, value) => dispatch({type: "REMOVE_FINAL_CLAIM_FLAG", value})
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setDisableActionDrawerButton,
  setPolicyHolderInviteDetails,
  setPageLoading,
  setCustomTaskExternalUserDetails,
  setTaskDetails,
  setStatusDetails,
  removeStatusDetails,
  updateStatusDetails,
  setDisplayGallery,
  setMediaIndex,
  setActionableItems,        
  addActionableItem,         
  removeActionableItem ,      
  setTempClaimFlags,
  setFinalClaimFlags,
  removeFinalClaimFlags,
  setActionableSingleItem,
  resetStatusDetails,
  setReferralDetails,
  addActionableItemUsername,
  setActionableItemsUsernames,
  setAssignDetails,
  setCreateClaimRequestBody,
  resetCreateClaimRequestBody,
  setCreateAssignmentRequestBody,
  resetCreateAssignmentRequestBody
};
