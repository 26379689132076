import { combineReducers } from "redux";
import { claimsSlice } from "./claims-reducer";
import { assignmentsSlice } from "./assignments-reducer";
import { dashboardSlice } from "./dashboard-reducer";
import { authSlice } from "./auth-reducer";
import { teamMembersSlice } from "./teamMembers-reducer";
import { customTasksSlice } from "./customTasks-reducer";
import { mediaSlice } from "./media-reducer";
import { flagsSlice } from "./flags-reducer";
var rootReducer = combineReducers({
    authState: authSlice === null || authSlice === void 0 ? void 0 : authSlice.reducer,
    claimsState: claimsSlice === null || claimsSlice === void 0 ? void 0 : claimsSlice.reducer,
    assignmentState: assignmentsSlice === null || assignmentsSlice === void 0 ? void 0 : assignmentsSlice.reducer,
    dashboardState: dashboardSlice === null || dashboardSlice === void 0 ? void 0 : dashboardSlice.reducer,
    teamMembersState: teamMembersSlice === null || teamMembersSlice === void 0 ? void 0 : teamMembersSlice.reducer,
    customTasksState: customTasksSlice === null || customTasksSlice === void 0 ? void 0 : customTasksSlice.reducer,
    mediaState: mediaSlice === null || mediaSlice === void 0 ? void 0 : mediaSlice.reducer,
    flagsState: flagsSlice === null || flagsSlice === void 0 ? void 0 : flagsSlice.reducer
});
export default rootReducer;
