/* eslint-disable react/prop-types */
import { useState,useEffect } from "react";
import MDBox from "../../components/MDBox";


import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import TabList from '@mui/lab/TabList';
import  { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import pxToRem from "../../assets/theme/functions/pxToRem";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { tabLabels,tabContent, validateRequiredFields } from "./ClaimUtils";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import { setCreateClaimRequestBody, setDisableActionDrawerButton, useMaterialUIController } from "../../context";
import { drawerContainer } from "./common-styles/styles";

function CreateClaim() {
  const [value, setValue] = useState(0);
  const [controller,dispatch] = useMaterialUIController();
  const { claimRequestBody,assignmentRequestBody } = controller


  useEffect(() => {
    const defaultDate = new Date()
    setCreateClaimRequestBody(dispatch,'lossDate', defaultDate)  
    setCreateClaimRequestBody(dispatch,'receiptDate', defaultDate)  
}, []);


  useEffect(() => {
    const areAllRequiredFieldsPresent = validateRequiredFields(claimRequestBody,assignmentRequestBody);

    if (!areAllRequiredFieldsPresent) 
        setDisableActionDrawerButton(dispatch, true);
    else 
        setDisableActionDrawerButton(dispatch, false);
    
    
  }, [claimRequestBody,assignmentRequestBody])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const nextButtonHandler = () =>
  {
    setValue((prevValue) => prevValue + 1);
  }
  return (
    <DashboardLayout>
      <MDBox sx={drawerContainer}>
        <MDBox >
        <TabContext value={value} >
          <TabList  
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            selectionFollowsFocus
            allowScrollButtonsMobile
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '& svg': {
                  fontSize: `${pxToRem(40)} !important`, 
                },
                '&.Mui-disabled': { opacity: 0.3 },
              },
             
            }}
          >
            {tabLabels.map((label) => (
              <Tab key={label} label={label} />
            ))}
          </TabList>
          <TabPanel key={value} value={value} >
            {tabContent[value]}
          </TabPanel>
        </TabContext>
        </MDBox>
      {value !== (tabLabels.length -1) && 
      <MDBox sx={{display:"flex", justifyContent:"flex-end" ,mb:4,mr:2,}}>
        <MDButton 
          variant="gradient" 
          onClick={nextButtonHandler} 
          color="veriskWhite" 
          sx={{  
                padding : 1, 
                boxShadow : "6px 6px 6px #00000029",
                opacity:1,
             
              }}
        >
          <MDTypography 
              color={"veriskBlue"} 
              sx={{
                    fontSize : pxToRem(15), 
                    fontWeight:"medium"
                  }}
          >
            {"Next"}
          </MDTypography>
        </MDButton>
        </MDBox>
}     </MDBox>
    </DashboardLayout>
  );
  /*
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const schema = yup.object().shape({
    claimNumber: yup.string().required("Please provide claim number"),
    peril: yup.number().required("Please provide peril"),
    lossDescription: yup.string().required("Please provide description of loss"),
    lossDate: yup.string().required("Please provide loss date"),
    receiptDate: yup.string().required("Please provide receipt date"),
    cause: yup.number().required("Please provide cause"),
    specialAttention: yup.number().required("Please provide special attention"),
    claimType: yup.number().required("Please provide claim type"),
    policy: yup.object().shape({
      policyNumber: yup.string().required("Please provide policy number"),
      inceptionDate: yup.string().required("Please provide inception date"),
      effectiveDate: yup.string().required("Please provide inception date"),
      expiryDate: yup.string().required("Please provide expiry date"),
      buildingSumInsured: yup.number().required("Please provide building sum insured"),
      buildingExcess: yup.number().required("Please provide building excess"),
      contentSumInsured: yup.number().required("Please provide content sum insured"),
      contentExcess: yup.number().required("Please provide content excess"),
    }),
    policyholder: yup.object().shape({
      customerName: yup.string().required("Please provide customer name"),
      riskAddress:  yup.object().shape({
        type: yup.number().required("Please provide address type"),
        street: yup.string().required("Please provide street"),
        city: yup.string().required("Please provide city"),
        postal: yup.string().required("Please provide postal"),
        country: yup.string().required("Please provide country"),
        state: yup.string().required("Please provide state"),
        county: yup.string().required("Please provide county"),
      }),
      email: yup.string().email().required("Please provide email address"),
      phone: yup.object().shape({
        type: yup.number().required("Please provide phone type"),
        number: yup.string().required("Please provide number"),
        extension: yup.string().required("Please provide extension"),
      }),
    }),
  });


  const {
    register,
    // formState: { errors },
    handleSubmit,
    setError,
    // reset,
    setValue,
    control,
    // watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // claimNumber: yup.string().required("Please provide claim number"),
      peril: PerilTypes[0].value,
      lossDescription: 'Loss occurred in sitting room yesterday',
      lossDate: '2023-09-01',
      receiptDate: '2023-10-18',
      cause: Causes[0].value,
      specialAttention: SpecialAttentions[0].value,
      claimType: ClaimTypes[0].value,
      policy: {
        policyNumber: 'PL-283289ADQ',
        inceptionDate: '2023-08-11',
        effectiveDate: '2023-09-04',
        expiryDate: '2023-10-01',
        buildingSumInsured: 1000,
        buildingExcess: 100,
        contentSumInsured: 2000,
        contentExcess: 200,
      },
      policyholder: {
        customerName: 'Mr. James Brown',
        riskAddress: {
          type: AddressTypes[0].value,
          street: '700 The Farm Houses, South Barrwood Road',
          city: 'Manchester',
          postal: 'MN0 8IL',
          country: 'GB',
          state: 'ENG',
          county: 'West Midlands',
        },
        email: 'jamesbrown@gmail.com',
        phone: {
          type: PhoneTypes[0].value,
          number: '449029940039',
          extension: '123',
        },
      }
    },
  });

  const onSubmit = async (claim) => {
    try {
      const response = await dispatch(claimCreateClaim(claim)).unwrap();
      dispatch(claimGetClaims());
      dispatch(dashboardGetDashboard());
      navigate('/claims');
    } catch (e){
      setError('serverError', {
        type: 'serverSideError',
        message: 'something is wrong '
      })
    }
  };

  const handleAddressChange = (event) => {
    setValue('policyholder.riskAddress.type', event.target.value);
  };
  const handlePhoneChange = (event) => {
    setValue('policyholder.phone.type', event.target.value);
  };
  const handlePerilChange = (event) => {
    setValue('peril', event.target.value);
  };
  const handleSpecialAttentionChange = (event) => {
    setValue('specialAttention', event.target.value);
  };
  const handleCauseChange = (event) => {
    setValue('cause', event.target.value);
  };
  const handleClaimTypeChange = (event) => {
    setValue('claimType', event.target.value);
  };
  
  const onError = (errors, e) => console.log(errors, e);
  
  return ( 
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                  Create a new claim
                </MDTypography>
                <MDTypography display="block" variant="button" color="white" my={1}>
                  Enter claim details
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleSubmit(onSubmit, onError)}> 

              
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" mt={1} mb={3}>
                          Policyholder Details
                        </MDTypography>
                        <MDBox mb={2}>
                          <MDInput type="text" label="Customer Name" {...register("policyholder.customerName")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MDTypography variant="h6" fontWeight="regular" mt={1}>
                              Risk Address
                            </MDTypography>
                          </Grid>

                          <Grid item xs={12}>
                            <MDBox mb={2}>
                              <InputLabel id="address-type">Address Type</InputLabel>
                              <Controller name="policyholder.riskAddress.type" control={control}
                                          render={() => (
                                            <Select
                                              defaultValue={AddressTypes[0].value}
                                              labelId="address-type"
                                              id="address_type"
                                              {...register("policyholder.riskAddress.type")}
                                              label="Address Type"
                                              fullWidth
                                              variant="standard" size="small"
                                              onChange={handleAddressChange}
                                            >
                                              {AddressTypes.map((address, index) =>(
                                                <MenuItem value={address.value} key={index}>{address.name}</MenuItem>
                                              ))}
                                            </Select>
                                          )}
                              >
                                
                              </Controller>
                              
                              
                            </MDBox>
                          </Grid>

                        
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <MDInput type="text" label="Street" {...register("policyholder.riskAddress.street")} variant="standard" size="small" fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <MDInput type="text" label="City" {...register("policyholder.riskAddress.city")} variant="standard" size="small" fullWidth />
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <MDInput type="text" label="Postal" {...register("policyholder.riskAddress.postal")} variant="standard" size="small" fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <MDInput type="text" label="Country" {...register("policyholder.riskAddress.country")} variant="standard" size="small" fullWidth />
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <MDInput type="text" label="State" {...register("policyholder.riskAddress.state")} variant="standard" size="small" fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <MDInput type="text" label="County" {...register("policyholder.riskAddress.county")} variant="standard" size="small" fullWidth />
                            </MDBox>
                          </Grid>
                        </Grid>

                     
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MDTypography variant="h6" fontWeight="regular" mt={1}>
                              Phone
                            </MDTypography>
                          </Grid>

                          <Grid item xs={12}>
                            <MDBox mb={2}>
                              <InputLabel id="phone-type">Phone Type</InputLabel>
                              <Controller name="policyholder.phone.type" control={control}
                                          render={() => (
                                            <Select
                                              defaultValue={PhoneTypes[0].value}
                                              labelId="phone-type"
                                              id="phone_type"
                                              {...register("policyholder.phone.type")}
                                              label="Phone Type"
                                              fullWidth
                                              variant="standard" size="small"
                                              onChange={handlePhoneChange}
                                            >
                                              {PhoneTypes.map((phone, index) =>(
                                                <MenuItem value={phone.value} key={index}>{phone.name}</MenuItem>
                                              ))}
                                            </Select>
                                          )}
                              >

                              </Controller>
                              
                              
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <MDInput type="text" label="Number" {...register("policyholder.phone.number")} variant="standard" size="small" fullWidth />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <MDInput type="text" label="Extension" {...register("policyholder.phone.extension")} variant="standard" size="small" fullWidth />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <MDBox mt={2} mb={2}>
                          <MDInput type="email" label="Email" {...register("policyholder.emailAddress")} variant="standard" size="small" fullWidth />
                        </MDBox>
                      </Grid>
                    </Grid>

                   
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" mt={1} mb={3}>
                          Claim Information
                        </MDTypography>

                        <MDBox mb={3}>
                          <MDInput type="text" label="Claim Number" {...register("claimNumber")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <MDInput type="text" label="Date of Loss" {...register("lossDate")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <MDInput type="text" label="Date Received" {...register("receiptDate")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <InputLabel id="peril">Peril</InputLabel>
                          <Controller name="peril" control={control}
                                      render={() => (
                                        <Select
                                          defaultValue={PerilTypes[0].value}
                                          labelId="peril"
                                          id="peril"
                                          {...register("peril")}
                                          label="Peril"
                                          fullWidth
                                          variant="standard" size="small"
                                          onChange={handlePerilChange}
                                        >
                                          {PerilTypes.map((peril, index) =>(
                                            <MenuItem value={peril.value} key={index}>{peril.name}</MenuItem>
                                          ))}
                                        </Select>
                                      )}
                          >

                          </Controller>
                          
                        </MDBox>

                        <MDBox mb={3}>
                          <InputLabel id="cause">Cause</InputLabel>
                          <Controller name="cause" control={control}
                                      render={() => (
                                        <Select
                                          defaultValue={Causes[0].value}
                                          labelId="cause"
                                          id="cause"
                                          {...register("cause")}
                                          label="Cause"
                                          fullWidth
                                          variant="standard" size="small"
                                          onChange={handleCauseChange}
                                        >
                                          {Causes.map((cause, index) =>(
                                            <MenuItem value={cause.value} key={index}>{cause.name}</MenuItem>
                                          ))}
                                        </Select>
                                      )}
                          >

                          </Controller>
                          
                        </MDBox>

                        <MDBox mb={3}>
                          <MDInput type="text" label="Description of Loss" {...register("lossDescription")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <InputLabel id="claim-type">Claim Type</InputLabel>
                          <Controller name="claimType" control={control}
                                      render={() => (
                                        <Select
                                          defaultValue={ClaimTypes[0].value}
                                          labelId="claim-type"
                                          id="claim_type"
                                          {...register("claimType")}
                                          label="Claim Type"
                                          fullWidth
                                          variant="standard" size="small"
                                          onChange={handleClaimTypeChange}
                                        >
                                          {ClaimTypes.map((claimType, index) =>(
                                            <MenuItem value={claimType.value} key={index}>{claimType.name}</MenuItem>
                                          ))}
                                        </Select>
                                      )}
                          >

                          </Controller>
                          
                        </MDBox>

                        <MDBox mb={3}>
                          <InputLabel id="special-attention">Special Attention</InputLabel>
                          <Controller name="specialAttention" control={control}
                                      render={() => (
                                        <Select
                                          defaultValue={SpecialAttentions[0].value}
                                          labelId="special-attention"
                                          id="special_attention"
                                          {...register("specialAttention")}
                                          label="Special Attention"
                                          fullWidth
                                          variant="standard" size="small"
                                          onChange={handleSpecialAttentionChange}
                                        >
                                          {SpecialAttentions.map((specialAttention, index) =>(
                                            <MenuItem value={specialAttention.value} key={index}>{specialAttention.name}</MenuItem>
                                          ))}
                                        </Select>
                                      )}
                          >

                          </Controller>
                          
                        </MDBox>
                      </Grid>
                    </Grid>

                  
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" mt={1} mb={3}>
                          Policy Information
                        </MDTypography>

                        <MDBox mb={3}>
                          <MDInput type="text" label="Policy Number" {...register("policy.policyNumber")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <MDInput type="text" label="Inception Date" {...register("policy.inceptionDate")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <MDInput type="text" label="Effective Date" {...register("policy.effectiveDate")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <MDInput type="text" label="Expiry Date" {...register("policy.expiryDate")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <MDInput type="number" label="Building Sum Insured" {...register("policy.buildingSumInsured")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <MDInput type="number" label="Building Excess" {...register("policy.buildingExcess")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <MDInput type="number" label="Content Sum Insured" {...register("policy.contentSumInsured")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mb={3}>
                          <MDInput type="number" label="Content Excess" {...register("policy.contentExcess")} variant="standard" size="small" fullWidth />
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth type="submit">
                            create claim
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                    
                  </MDBox>
                
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

    </DashboardLayout>
  );
  */

}

export default CreateClaim;